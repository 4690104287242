import React from "react";
import "./App.css";
import * as Sentry from "@sentry/react";

import Messenger from "../Messenger/Messenger";

const App = () => {
  return (
    <div className="App">
      <Messenger></Messenger>
    </div>
  );
};

export default Sentry.withProfiler(App);
