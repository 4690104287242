import * as types from "../constants/actionTypes";
import * as messageApi from "../../api/messageApi";
import _get from "lodash/get";

export function addMessage(message) {
  return { type: types.ADD_MESSAGE, message };
}

export function updateMessage(internal_message_id, status) {
  return {
    type: types.UPDATE_MESSAGE_STATUS,
    action: { internal_message_id, status },
  };
}

export function loadMessagesSuccess(messages) {
  return { type: types.LOAD_MESSAGES_SUCCESS, messages };
}

export function loadMessageSuccess(message) {
  return { type: types.LOAD_MESSAGE_SUCCESS, message };
}

export function removePreviousOptions() {
  return { type: types.REMOVE_PREVIOUS_OPTIONS };
}

export function createOptionSuccess(message) {
  return { type: types.CREATE_OPTION_SUCCESS, message };
}

export function isFetching(isFetching) {
  return { type: types.WAITING_FOR_MESSAGE, isFetching };
}

export function loadMessages(jwt_token) {
  return function (dispatch) {
    return messageApi
      .getMessages(jwt_token)
      .then((messages) => {
        dispatch(loadMessagesSuccess(messages));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadMessage(jwt_token) {
  return function (dispatch) {
    return messageApi
      .getCurrentMessage(jwt_token)
      .then((message) => {
        dispatch(loadMessageSuccess(message));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function retryChatOption(
  option,
  type = null,
  jwt_token = null,
  message
) {
  const internal_message_id = _get(message, "internal_message_id");

  return function (dispatch) {
    dispatch(updateMessage(internal_message_id, "retrying"));
    if (type !== "image") dispatch(isFetching(true));

    return messageApi
      .saveChatOption(option, type, jwt_token)
      .then((savedOption) => {
        if (type === "image") dispatch(isFetching(true));
        setTimeout(() => {
          dispatch(createOptionSuccess(savedOption));
          dispatch(updateMessage(internal_message_id, "complete"));
        }, 1500);
      })
      .catch((error) => {
        dispatch(updateMessage(internal_message_id, "failed"));
        dispatch(isFetching(false));

        // TODO: Verify - error should have been logged by sentry, what to do now? Anything?
        //throw error;
      })
      .then(function () {
        setTimeout(() => {
          dispatch(isFetching(false));
        }, 1500);
      });
  };
}

export function saveChatOption(option, type = null, jwt_token = null, message) {
  const internal_message_id = _get(message, "internal_message_id");

  return function (dispatch) {
    dispatch(addMessage(message));
    if (type !== "image") dispatch(isFetching(true));
    dispatch(removePreviousOptions());

    return messageApi
      .saveChatOption(option, type, jwt_token)
      .then((savedOption) => {
        if (type === "image") dispatch(isFetching(true));
        setTimeout(() => {
          dispatch(createOptionSuccess(savedOption));
          dispatch(updateMessage(internal_message_id, "complete"));
        }, 1500);
      })
      .catch((error) => {
        dispatch(updateMessage(internal_message_id, "failed"));
        dispatch(isFetching(false));

        // TODO: Verify - error should have been logged by sentry, what to do now? Anything?
        //throw error;
      })
      .then(function () {
        setTimeout(() => {
          dispatch(isFetching(false));
        }, 1500);
      });
  };
}
