import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import MessageList from "../MessageList/MessageList";
import "./Messenger.css";
import * as accountActions from "../../redux/actions/accountActions";

interface iMessengerProps {
  actions: any;
}

interface iMessengerState {}

const Messenger = ({ actions }: iMessengerProps) => {
  const [token, setToken] = useState<string | null>("unset");

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token: string | null = params.get("token");

    setToken(token);

    actions.registerAccount(token).catch((e: any) => {
      setToken(null);
    });
  }, [actions]);

  const DisplayMessenger = () => {
    if (token !== null && token !== "unset") {
      return <MessageList />;
    } else if (token === null) {
      return (
        <div className="error-box">
          Sajnos úgy tűnik, hogy érvénytelen linket használt
        </div>
      );
    }
  };

  return (
    <div className="messenger">
      <div className="scrollable content" id="message-list">
        {DisplayMessenger()}
      </div>
    </div>
  );
};

function mapStateToProps(state: iMessengerState) {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(accountActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Messenger);
