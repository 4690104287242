import { memo } from "react";

const AvatarBox = memo(function AvatarBox() {
  return (
    <div className="image-avatar">
      <img src="autotal-logo.png" alt="Autotal" className="profile-pic" />
    </div>
  );
});

export default AvatarBox;
