import { memo } from "react";

interface Props {
  src: string;
}

const Image = memo(function Image({ src }: Props) {
  return <img src={src} alt="Chat message" />;
});

export default Image;
