import { combineReducers } from "redux";
import messages from "./chatReducer";
import isFetching from "./apiStatusReducer";
import account from "./accountReducer";

const rootReducer = combineReducers({
  messages,
  isFetching,
  account,
});

export default rootReducer;
