import { useState } from "react";
import DatePickerJS from "react-mobile-datepicker";

import dateConfig from "./dateConfig";

interface Props {
  isOpen: Boolean;
  handleSelect: (time: Date) => void;
  handleCancel: () => void;
}

const DatePicker = ({ isOpen, handleSelect, handleCancel }: Props) => {
  const [time] = useState(new Date());
  return (
    <DatePickerJS
      value={time}
      isOpen={isOpen}
      onSelect={handleSelect}
      onCancel={handleCancel}
      dateConfig={dateConfig}
      theme={"ios"}
      confirmText="OK"
      cancelText="Cancel"
    />
  );
};

export default DatePicker;
