import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/hu";
import _uniqueId from "lodash/uniqueId";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import "./Message.css";
import { disableElement, disableInputs } from "../../utils";
import {
  saveChatOption,
  retryChatOption,
} from "../../redux/actions/chatActions";
import AvatarBox from "../common/AvatarBox/AvatarBox";
import Image from "../common/Image/Image";
import DatePicker from "../common/DatePicker/DatePicker";
import { add } from "lodash";

interface addon {
  id: number;
  node_id: number;
  isActive: Boolean;
  type: string;
  data?: {};
  complete: Boolean;
}
interface Props {
  id?: any;
  isMine?: Boolean;
  author?: any;
  status?: any;
  internalMessageId?: string;
  startsSequence?: any;
  endsSequence?: any;
  showTimestamp?: any;
  data?: any;
  complete?: Boolean;
}

const Message = ({
  id,
  isMine,
  author,
  internalMessageId,
  startsSequence,
  endsSequence,
  showTimestamp,
  data,
  status,
  complete,
}: Props) => {
  const [addons, setAddons] = useState<addon[]>([]);

  const messages = useSelector((state: any) => state.messages);
  const account = useSelector((state: any) => state.account);

  const dispatch = useDispatch();

  const [friendlyTimestamp, setFriendlyTimestamp] = useState<
    string | undefined
  >();

  // TODO : REMOVE THIS DEBUGGER
  useEffect(() => {
    console.log("ADDONS STATE UPDATE DEBUGGER", addons);
  }, [addons]);

  useEffect(() => {
    moment.locale("hu");
    setFriendlyTimestamp(moment(new Date()).locale("hu").format("LLLL"));
  }, []);

  const renderTextMessageComponent = () => {
    return (
      <div
        className="bubble"
        title={friendlyTimestamp}
        dangerouslySetInnerHTML={{ __html: data.message }}
      ></div>
    );
  };

  const renderImageMessageComponent = () => {
    return (
      <div className="message-image">
        <Loader
          className="loader"
          type="ThreeDots"
          color="#00BFFF"
          visible={status === "loading"}
        />
        <Image src={data.message} />
      </div>
    );
  };

  const renderMessage = (data: any) => {
    if (complete) {
      disableInputs();

      (document.getElementsByName(
        "compose-input"
      )[0] as HTMLInputElement).placeholder =
        "Köszönjük, végeztünk és kiléphet.";

      disableElement("camera-icon");

      return <div className="complete">Köszönjük, végeztünk és kiléphet. </div>;
    }
    if (data.message_type === "image") {
      return renderImageMessageComponent();
    } else {
      return renderTextMessageComponent();
    }
  };

  const clickButton = (e: any) => {
    dispatch(
      saveChatOption(
        {
          payload: e.target.value,
          payload_type: "button",
        },
        null,
        account.jwt_token,
        {
          author: "user",
          status: "loading",
          internal_message_id: _uniqueId("user-"),
          node_messages: [
            {
              message_type: "text",
              message: e.target.value,
              created_at: new Date().getTime(),
            },
          ],
        }
      )
    );
  };

  const retrySend = (e: any) => {
    e.preventDefault();
    if (internalMessageId !== undefined) {
      // Find the message in the redux store

      let messageToRetry = messages.filter((message: any) => {
        if (
          message.internal_message_id !== undefined &&
          message.internal_message_id === internalMessageId
        ) {
          return message;
        }
        return false;
      });

      if (messageToRetry[0].node_messages[0].message_type === "image") {
        dispatch(
          retryChatOption(
            messageToRetry[0].node_messages[0].formData,
            "image",
            account.jwt_token,
            messageToRetry[0]
          )
        );
      } else {
        dispatch(
          retryChatOption(
            {
              payload: messageToRetry[0].node_messages[0].message,
              payload_type: "text",
            },
            "text",
            account.jwt_token,
            messageToRetry[0]
          )
        );
      }
    }
  };

  const renderOptions = function (data: any) {
    if (data.options !== undefined && data.options.length > 0) {
      return (
        <div className="option-list">
          {Object.keys(data.options).map((keyOuter) => {
            return Object.keys(data.options[keyOuter]).map((keyInner) => {
              // Check if option is an addon and render it
              switch (data.original_data[keyOuter].option_type) {
                case "button":
                  return (
                    <button
                      key={`${keyOuter}`}
                      value={data.options[keyOuter][keyInner]}
                      onClick={(e) => clickButton(e)}
                    >
                      {keyInner}
                    </button>
                  );
                case "datepicker":
                  if (
                    !addonCompelete(parseInt(data.original_data[keyOuter].id))
                  ) {
                    initAddon(
                      "datepicker",
                      parseInt(data.original_data[keyOuter].id),
                      parseInt(data.original_data[keyOuter].node_id)
                    );
                    return (
                      <button
                        key={`${keyOuter}`}
                        value={data.options[keyOuter][keyInner]}
                        onClick={(e) =>
                          clickDatepickerButton(
                            e,
                            parseInt(data.original_data[keyOuter].id),
                            parseInt(data.original_data[keyOuter].node_id)
                          )
                        }
                      >
                        {keyInner}
                      </button>
                    );
                  }
                  break;
                default:
              }
            });
          })}
        </div>
      );
    }
  };

  let retryBox: any = "";
  if (status === "failed" || status === "retrying") {
    retryBox = (
      <div className="bubble-container">
        <div className="error-retry message">
          Hiba történt az üzenet küldésekor. Kérjük, ellenőrizze internet
          kapcsolatát.
          {status === "failed" && (
            <button className="text-link" onClick={(e) => retrySend(e)}>
              Próbálja újra?
            </button>
          )}
          {status === "retrying" && <span>Retrying....</span>}
        </div>
      </div>
    );
  }

  const addonCompelete = (addon_id: number) => {
    let addonExists: addon | false | undefined =
      addons !== undefined && addons.length > 0
        ? addons.find((x) => x!.id === addon_id)
        : false;

    if (addonExists) {
      return addonExists.complete;
    } else return false;
  };

  const handleDatePickerSelect = (addon_id: number, time: string) => {
    const newAddons = addons.map((addon) => {
      if (addon.id === addon_id) {
        return {
          ...addon,
          isActive: false,
          data: { date: time },
          complete: true,
        };
      }
      return addon;
    });

    setAddons(newAddons);

    dispatch(
      saveChatOption(
        {
          payload: time,
          payload_type: "button",
          addon_type: "datepicker",
        },
        null,
        account.jwt_token,
        {
          author: "user",
          status: "loading",
          internal_message_id: _uniqueId("user-"),
          node_messages: [
            {
              message_type: "text",
              message: time,
              addon_type: "datepicker",
              created_at: new Date().getTime(),
            },
          ],
        }
      )
    );
  };

  const initAddon = (addon: string, addon_id: number, node_id: number) => {
    switch (addon) {
      case "datepicker":
        let addonExists: addon | false | undefined =
          addons !== undefined && addons.length > 0
            ? addons.find((x) => x!.id === addon_id)
            : false;

        if (!addonExists) {
          const newAddon: addon = {
            id: addon_id,
            node_id: node_id,
            isActive: false,
            type: "datepicker",
            complete: false,
          };

          setAddons((addons) => [...addons, newAddon]);
        }

        break;
      default:
        return;
    }
  };

  const renderAddons = () => {
    return (
      <>
        {addons.map((addon) => {
          if (addon.type === "datepicker") {
            return (
              <DatePicker
                key={addon.id}
                isOpen={addon.isActive}
                handleSelect={handleAddonSelect(addon.id, "datepicker")}
                handleCancel={handleAddonCancel(addon.id)}
              />
            );
          }
        })}
      </>
    );
  };

  const closeAddon = (addon_id: number) => {
    const newAddons = addons.map((addon) => {
      if (addon.id === addon_id) {
        return { ...addon, isActive: false };
      }
      return addon;
    });

    setAddons(newAddons);
  };

  let handleAddonSelect = (addon_id: number, type: string) => {
    switch (type) {
      case "datepicker":
        return (time: Date) => {
          let formattedTime = moment(time).format("YYYY-MM-DD");
          handleDatePickerSelect(addon_id, formattedTime);
        };

      default:
        return () => {
          closeAddon(addon_id);
        };
    }
  };

  let handleAddonCancel = (addon_id: number) => {
    return () => {
      closeAddon(addon_id);
    };
  };

  const clickDatepickerButton = (e: any, addon_id: number, node_id: number) => {
    const newAddons = addons.map((addon) => {
      if (addon.id === addon_id) {
        return { ...addon, isActive: true };
      }
      return addon;
    });

    setAddons(newAddons);
  };

  return (
    <div
      className={[
        "message",
        `${isMine ? "mine" : ""}`,
        `${status === "failed" ? "error" : ""}`,
        `${status === "loading" ? "loading" : ""}`,
      ].join(" ")}
    >
      {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}

      {data.options === undefined && (
        <div className="bubble-container">
          <div className="avatar-box">
            {author === "bot" && endsSequence && <AvatarBox />}
          </div>

          {renderMessage(data)}
        </div>
      )}

      <div className="bubble-container">
        <div className="avatar-box"></div>
        {renderOptions(data)}
      </div>

      {retryBox}
      {renderAddons()}
    </div>
  );
};

export default Message;
