import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Compose from "../Compose/Compose";
import Toolbar from "../Toolbar/Toolbar";
import Message from "../Message/Message";
import moment from "moment";
import { animateScroll as scroll } from "react-scroll";
import _uniqueId from "lodash/uniqueId";

import "./MessageList.css";
import { loadMessage } from "../../redux/actions/chatActions";
import { TypingIndicator } from "../common/TypingIndicator/TypingIndicator";

const MY_USER_ID = "user";

const MessageList = () => {
  const messages = useSelector((state: any) => state.messages);
  const account = useSelector((state: any) => state.account);
  const isFetching = useSelector((state: any) => state.isFetching);

  const dispatch = useDispatch();

  const [firstMessageLoaded, setFirstMessageLoaded] = useState<Boolean>(false);

  useEffect(() => {
    const scrollToBottom = () => {
      let ver = iOSversion();

      if (ver !== undefined && ver[0] <= 10) {
        document.body.scrollTop = 9999999999;
      } else {
        setTimeout(() => {
          scroll.scrollToBottom({
            containerId: "message-list",
            duration: 500,
          });
        }, 100);
      }
    };

    if (account !== null && firstMessageLoaded === false) {
      dispatch(loadMessage(account.jwt_token));
      setFirstMessageLoaded(true);
    }

    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [account, firstMessageLoaded, messages, dispatch]);

  const iOSversion = () => {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
      var v: any = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
      return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
    }
  };

  const renderMessages = () => {
    let currentMessageIndex = 0;
    let messageCollection = [];

    if (messages) {
      const messageCount = messages.length;

      while (currentMessageIndex < messageCount) {
        let i = 0;
        let messageOutputs = 0;
        let messageStatus = "complete";
        let messageInternalId = "";

        if (messages[currentMessageIndex].status !== undefined) {
          messageStatus = messages[currentMessageIndex].status;
        }

        if (messages[currentMessageIndex].internal_message_id !== undefined) {
          messageInternalId = messages[currentMessageIndex].internal_message_id;
        }

        if (messages[currentMessageIndex].node_messages !== undefined) {
          messageOutputs = messages[currentMessageIndex].node_messages.length;
        }

        while (i < messageOutputs) {
          let previous = messages[currentMessageIndex].node_messages[i - 1];
          let current = messages[currentMessageIndex].node_messages[i];
          let author = messages[currentMessageIndex].author;
          if (!author) {
            author = "bot";
          }
          let next = messages[currentMessageIndex].node_messages[i + 1];
          let isMine = author === MY_USER_ID;
          let currentMoment = moment(current.created_at);
          let prevBySameAuthor = false;
          let nextBySameAuthor = false;
          let startsSequence = true;
          let endsSequence = true;
          let showTimestamp = currentMessageIndex === 0;

          if (previous) {
            let previousMoment = moment(previous.created_at);
            let previousDuration = moment.duration(
              currentMoment.diff(previousMoment)
            );
            prevBySameAuthor = previous.author === author;

            if (prevBySameAuthor && previousDuration.as("hours") < 1) {
              startsSequence = false;
            }

            if (previousDuration.as("hours") < 1) {
              showTimestamp = false;
            }
          }

          if (next) {
            let nextMoment = moment(next.created_at);
            let nextDuration = moment.duration(nextMoment.diff(currentMoment));
            nextBySameAuthor = next.author === author;

            if (nextBySameAuthor && nextDuration.as("hours") < 1) {
              endsSequence = false;
            }
          }

          let messageKey = _uniqueId("system-");
          if (
            messages[currentMessageIndex].node_messages[i]
              .internal_message_id !== undefined
          ) {
            messageKey =
              messages[currentMessageIndex].node_messages[i]
                .internal_message_id;
          }
          messageCollection.push(
            <Message
              key={messageKey}
              id={messageKey}
              isMine={isMine}
              author={author}
              status={messageStatus}
              internalMessageId={messageInternalId}
              startsSequence={startsSequence}
              endsSequence={endsSequence}
              showTimestamp={showTimestamp}
              data={current}
            />
          );

          // Proceed to the next message.
          i += 1;
        }

        if (messages[currentMessageIndex].is_exit_node) {
          messageCollection.push(
            <Message
              key={_uniqueId("system-") + "-end"}
              complete={true}
              status={messageStatus}
              internalMessageId={messageInternalId}
              data="finished"
            />
          );
        }

        if (messages[currentMessageIndex].node_options !== undefined) {
          let messageOptions =
            messages[currentMessageIndex].node_options.length;
          let y = 0;
          let options = [];
          while (y < messageOptions) {
            if (
              messages[currentMessageIndex].node_options[y].option_type !==
              "image"
            ) {
              let option_text =
                messages[currentMessageIndex].node_options[y].option_text;
              let option_payload =
                messages[currentMessageIndex].node_options[y].payload;
              let option_object: any = {};
              option_object[option_text] = option_payload;
              options.push(option_object);
            }
            y++;
          }

          // API returns image options that don't need to be presented to the user, dont create components for these
          if (options.length > 0) {
            let options_data = {
              author: "bot",
              type: "",
              options: options,
              original_data: { ...messages[currentMessageIndex].node_options },
            };
            messageCollection.push(
              <Message
                key={_uniqueId("system-") + "-options"}
                isMine={false}
                status={messageStatus}
                internalMessageId={messageInternalId}
                data={options_data}
              />
            );
          }
        }
        currentMessageIndex++;
      }
    }

    return messageCollection;
  };

  return (
    <div className="message-list">
      <Toolbar title="Autotál Kft." />

      <div className="message-list-container">{renderMessages()}</div>

      <TypingIndicator showIndicator={isFetching} />
      <Compose />
    </div>
  );
};

export default MessageList;
